<template>
  <div class="total">
       
    <div class="configure">  <div class="work-title-all  aligin-items-center " >  
        <div style="width:212px;height: 50px;">
            <p>管辖校区在读学员绑定率：<span class="color-">0.84%</span></p>
          <el-progress :show-text="false"  :percentage="0.84"></el-progress>
          </div>
            </div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane disabled label="通知公告" name="first1"> </el-tab-pane> 
        <el-tab-pane label="通知公告" name="first">
          <div  class="work-title bg-white">  
            <div
              class="work-title-all  aligin-items-center "
            > 
  <el-button  @click="JumpUrl('/parentCenter/Template',1)" :class="{'color-box':jobList==1}" >模板库</el-button>
  
  <el-button  @click="JumpUrl('/parentCenter/newNotification',2)"  :class="{'color-box':jobList==2}" >新建通知</el-button>
  
  <el-button  @click="JumpUrl('/parentCenter/newNotification',3)"  :class="{'color-box':jobList==3}" >全校群发</el-button>
            </div>

            <div
              class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap"
            >
            </div>
          <div style="margin-top: 10px">共计{{datalist.length}}条记录</div>
          <div style="margin-top: 10px">
            <as-list  :initdataList="datalist">
              <el-table-column
                align="center"
                prop="dangan"
                label="通知公告标题"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="摘要浏览"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name1"
                label="阅读率"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text3"
                label="创建人"
              ></el-table-column>
          
            
              <el-table-column
                align="center"
                prop="text7"
                label="发生日期"
              ></el-table-column>

              <el-table-column align="center" label="操作">
                <span class="text-blue cursor">删除</span>
              </el-table-column>
            </as-list>
          </div>
          </div>
          
        </el-tab-pane>
        
        <el-tab-pane  label="草稿箱" name="first1"> </el-tab-pane> 
      </el-tabs>
    </div>
    <addGrowthRecord
      :Visible="editVisible && editType === 4"
      @closepop="closeEditPop"
    ></addGrowthRecord>
  </div>
</template>
<script> 
import { fileType} from '@/config/index';
import addGrowthRecord from "../components/addGrowthRecord.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    addGrowthRecord,
  },
  data() {
    return { 
        jobList:1,
      archivesInput:"",//档案类型 
      fileType,
      stuInput: "",
      teachersInput: "",
      editType: 1, 
      fileTypeList: [], //布置教师
      datalist:[],
      datalist: [
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "60%",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
      ],
 
      value: "",
      activeName: "first", 
    };
  },
  methods: {
    closeEditPop(){
      this.editVisible=false;
    },
    job_List(t){ 
      this.jobList=t
     
    },
 
   
 
    JumpUrl(url,id) {
      this.job_List(id);
      this.$router.push(url);
    },
    handleClick(row) {
      console.log(row);
    },
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
  },
};
</script>

<style lang="scss">
.color-{
  color:#8cc5ff ;
}
.color-box{
      color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
}
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}

.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>