<template>
  <el-dialog
    title="新建成长档案"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="50%"
  >
    <div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="校区：">
          <span>驿都城校区</span>
        </el-form-item>
        <el-form-item label="学员：" prop="name">
          <el-select v-model="ruleForm.name" placeholder="请选择学员">
            <el-option
              v-for="(item, index) in student"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="档案类型：" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请选择档案类型">
            <el-option
              v-for="(item, index) in fileType"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="档案时间：" required>
          <el-col :span="11">
            <el-form-item prop="date1">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.date1"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-form-item prop="date2">
              <el-time-picker
                placeholder="选择时间"
                v-model="ruleForm.date2"
                style="width: 100%"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="档案推送：" prop="delivery">
          <el-switch v-model="ruleForm.delivery"></el-switch>
        </el-form-item>

        <el-form-item label="档案内容：" prop="desc">
          <el-input
            type="textarea"
            placeholder="最多可输入1000字"
            v-model="ruleForm.desc"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频：" style="width: 100%">
          <el-upload
            ref="videoUpload"
            action="#"
            accept=".mp4"
            :limit="3"
            :file-list="videoUpload"
            :on-change="viedoChangefile"
            list-type="picture-card"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"
              ><br />
              <i style="font-style: normal; font-size: 14px">上传视频</i></i
            >
            <div
              slot="file"
              v-for="(item, index) in videoUpload"
              :key="index"
              slot-scope="{ file }"
            >
              <video
                class="el-upload-list__item-thumbnail"
                :src="item.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  v-if="videoUpload.length"
                  class="el-upload-list__item-delete"
                  @click="videoRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div slot="tip" class="el-upload__tip">
              视频大小不超过200M，最多上传3个
            </div>
          </el-upload>
          <el-dialog :visible.sync="videoVisible">
            <img width="100%" :src="videoUpload" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="图片：" style="width: 100%">
          <el-upload
            ref="pictureUpload"
            action="#"
            :on-change="pictureChangefile"
            :file-list="pictureUpload"
            list-type="picture-card"
            :limit="12"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"
              ><br />
              <i style="font-style: normal; font-size: 14px">上传图片</i></i
            >
            <div
              slot="file"
              v-for="(item, index) in pictureUpload"
              :key="index"
              slot-scope="{ file }"
            >
              <img
                class="el-upload-list__item-thumbnail"
                :src="item.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="pictureRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div slot="tip" class="el-upload__tip">
              图片大小不超过9M，最多上传12张
            </div>
          </el-upload>
          <el-dialog :visible.sync="imageDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
       
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { fileType } from "@/config/index";
import { student } from "@/config/index";
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  data() {
    return {
      videoUpload: [],
      pictureUpload: [],
      imageDialogVisible: false,//图片上传
      dialogImageUrl: "", //图片上传
      disabled: false, //图片上传
      videoVisible: false,
      fileType,
      student,
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
      },
      dialogVisible: false, 

      change: "first",
      text: "",
      ClientName: "",
      checked: "",
      rules: {
        name: [
          { required: true, message: "请选择学员", trigger: "change" },
          // { required: true, message: '请输入学员名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: "请选择档案类型", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],

        desc: [
          { required: true, message: "请填写档案内容", trigger: "blur" },
          { max: 1000, message: "最多可输入1000字", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    //图片上传
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imageDialogVisible=true;
    },
    pictureRemove(file) {
      this.pictureUpload.splice(
        this.pictureUpload.findIndex((t) => t.url === file.url),
        1
      );
    },
    pictureChangefile(file, b) {
      var _this = this;
      const isLt2M = file.size / 1024 / 1024; 
      if (isLt2M > 9) {
        this.$message.error("上传图片大小不能超过9MB!");
      } else {
        _this.pictureUpload.push({
          name: "",
          url: URL.createObjectURL(file.url),
        }); 
      }
    },

    //图片上传

    //视频上传

    viedoChangefile(file, b) {
      var _this = this;
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 200MB!");
      } else { 
        _this.videoUpload.push({
          name: "",
          url: URL.createObjectURL(file.raw),
        }); 
      }
    },
    videoRemove(file) {
      this.videoUpload.splice(
        this.videoUpload.findIndex((t) => t.url === file.url),
        1
      );
    },
    //视频上传
    close() {
      this.$emit("closepop");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) { 
        } else { 
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
</style>
